export { default as InputBox } from "./input/input";
export { default as TabsInputBox } from "./tabs-input/tabs-input";
export { default as CheckBoxInputBox } from "./checkbox-input/checkbox-input";
export { default as DropdownInputBox } from "./dropdown-input/dropdown-input";
export { default as DatePickerInput } from "./date-picker-input/date-picker-input";
export { default as TickBoxInput } from "./tickbox-input/tickbox-input";
export { default as PrimaryButton } from "./button/button";
export { default as SwitchLanguage } from "./switch-lang/switch-lang";
export { default as AlertPopup } from "./alert/alert";
export { default as Menu } from "./menu/menu";
